import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_helpers/auth.guard';
import { AuthLoginGuard } from './_helpers/auth-login.guard';
import { LoginComponent } from './login/login.component';
import { BackofficeComponent } from './backoffice/backoffice.component';
import { RegisterComponent } from './register/register.component';
import { MainComponent } from './backoffice/main/main.component';
import { RoleComponent } from './backoffice/role/role.component';
import { AddRoleComponent } from './backoffice/role/add-role/add-role.component';
import { UserListComponent } from './backoffice/user-list/user-list.component';
import { AddUserComponent } from './backoffice/user-list/add-user/add-user.component';
import { UpdateUserComponent } from './backoffice/user-list/update-user/update-user.component';
import { UpdateRoleComponent } from './backoffice/role/update-role/update-role.component';
import { EtablissementComponent } from './backoffice/etablissement/etablissement.component';
import { AddEtablissementComponent } from './backoffice/etablissement/add-etablissement/add-etablissement.component';
import { UpdateEtablissementComponent } from './backoffice/etablissement/update-etablissement/update-etablissement.component';
import { EtbalissementemplComponent } from './Etablissements/etbalissementempl/etbalissementempl.component';
import { MatieresComponent } from './Etablissements/etbalissementempl/matieres/matieres.component';
import { AddMatiereComponent } from './Etablissements/etbalissementempl/matieres/add-matiere/add-matiere.component';
import { UpdateMatiereComponent } from './Etablissements/etbalissementempl/matieres/update-matiere/update-matiere.component';
import { EnseignantsComponent } from './Etablissements/etbalissementempl/enseignants/enseignants.component';
import { AddEnseignantComponent } from './Etablissements/etbalissementempl/enseignants/add-enseignant/add-enseignant.component';
import { UpdateEnseignantComponent } from './Etablissements/etbalissementempl/enseignants/update-enseignant/update-enseignant.component';
import { DirecteurHomeComponent } from './Etablissements/etbalissementempl/directeur-home/directeur-home.component';
import { TemplEnseignantComponent } from './Etablissements/Enseignant/templ-enseignant/templ-enseignant.component';
import { EnseignantHomeComponent } from './Etablissements/Enseignant/templ-enseignant/enseignant-home/enseignant-home.component';
import { ClassesEnseigComponent } from './Etablissements/Enseignant/templ-enseignant/classes-enseig/classes-enseig.component';

import { MatieresEnseigComponent } from './Etablissements/Enseignant/templ-enseignant/matieres-enseig/matieres-enseig.component';
import { ProfilEnseignantComponent } from './Etablissements/Enseignant/templ-enseignant/profil-enseignant/profil-enseignant.component';
import { UpdateInformationsComponent } from './Etablissements/Enseignant/templ-enseignant/profil-enseignant/update-informations/update-informations.component';
import { UpdatePasswordComponent } from './Etablissements/Enseignant/templ-enseignant/profil-enseignant/update-password/update-password.component';
import { UpdatephotoComponent } from './Etablissements/Enseignant/templ-enseignant/profil-enseignant/updatephoto/updatephoto.component';
import { AccueilComponent } from './Etablissements/Parent/templ-parent/accueil/accueil.component';
import { TemplParentComponent } from './Etablissements/Parent/templ-parent/templ-parent.component';
import { EnfantsComponent } from './Etablissements/etbalissementempl/enfants/enfants.component';
import { AddEnfantComponent } from './Etablissements/etbalissementempl/enfants/add-enfant/add-enfant.component';
import { UpdateEnfantComponent } from './Etablissements/etbalissementempl/enfants/update-enfant/update-enfant.component';
import { ListEnfantsComponent } from './Etablissements/Enseignant/templ-enseignant/list-enfants/list-enfants.component';
import { AjoutPhotoEnfantComponent } from './Etablissements/Enseignant/templ-enseignant/list-enfants/ajout-photo-enfant/ajout-photo-enfant.component';
import { ListesPhotosEnfantsComponent } from './Etablissements/Enseignant/templ-enseignant/list-enfants/listes-photos-enfants/listes-photos-enfants.component';
import { ParentsComponent } from './Etablissements/etbalissementempl/parents/parents.component';
import { AddParentComponent } from './Etablissements/etbalissementempl/parents/add-parent/add-parent.component';
import { UpdateParentComponent } from './Etablissements/etbalissementempl/parents/update-parent/update-parent.component';
import { ListesEnfantsComponent } from './Etablissements/Parent/templ-parent/listes-enfants/listes-enfants.component';
import { ListesPhotosEnfantsByParentComponent } from './Etablissements/Parent/templ-parent/listes-enfants/listes-photos-enfants-by-parent/listes-photos-enfants-by-parent.component';
import { ActualitesComponent } from './backoffice/actualites/actualites.component';
import { AddActualiteComponent } from './backoffice/actualites/add-actualite/add-actualite.component';
import { UpdateActualiteComponent } from './backoffice/actualites/update-actualite/update-actualite.component';
import { ViewEtablissementComponent } from './view-etablissement/view-etablissement.component';
import { SearchComponent } from './backoffice/search/search.component';
import { UpdatePasswordGlobaleComponent } from './update-password-globale/update-password-globale.component';
import { ContactsMarketingComponent } from './backoffice/contacts-marketing/contacts-marketing.component';
import { SigInListComponent } from './backoffice/sig-in-list/sig-in-list.component';
import { ListStatemntsByChildComponent } from './Etablissements/Enseignant/templ-enseignant/list-enfants/list-statemnts-by-child/list-statemnts-by-child.component';
import { ListStatementsComponent } from './Etablissements/Parent/templ-parent/listes-enfants/list-statements/list-statements.component';
import { DetailsEnfantComponent } from './Etablissements/Enseignant/templ-enseignant/list-enfants/details-enfant/details-enfant.component';
import { ParentdetailsEnfantComponent } from './Etablissements/Parent/templ-parent/listes-enfants/parentdetails-enfant/parentdetails-enfant.component';



const routes: Routes = [
  { path: '', redirectTo: '/Connexion', pathMatch: 'full' },
  { path: 'Connexion', component: LoginComponent, canActivate: [AuthLoginGuard] },
  { path: 'voir-rapport-établissement/:id', component: ViewEtablissementComponent, canActivate: [AuthLoginGuard] },

  {
    path: 'réinitialisation-motDePasse',
    component: RegisterComponent,
   
  },
  {
    path: 'reinitialisation/:token',
    component: UpdatePasswordGlobaleComponent,
   
  },
  
  {
    path: 'Super-Admin',
    component: BackofficeComponent,
   
    canActivate: [AuthGuard], //--> tebe3 login hedha yebloqui acces al backoffice 
  
    children: [
      { path: '', component: MainComponent },
      { path: 'rôles', component: RoleComponent },
      { path: 'rôles/ajout-rôle', component: AddRoleComponent },
      { path: 'rôles/modification-rôle/:id', component: UpdateRoleComponent },
      { path: 'utilisateurs', component: UserListComponent },
      { path: 'utilisateurs/ajout-utilisateur', component: AddUserComponent },
      { path: 'utilisateurs/modification-utilisateur/:id', component: UpdateUserComponent },
      { path: 'établissements', component: EtablissementComponent },
      { path: 'établissements/ajout-établissement', component: AddEtablissementComponent },
      { path: 'établissements/modification-établissement/:id', component: UpdateEtablissementComponent },
      { path: 'actualités', component: ActualitesComponent },
      { path: 'actualités/ajout-actualité', component: AddActualiteComponent },
      { path: 'actualités/modification-actualité/:id', component: UpdateActualiteComponent },
      { path: 'recherche/:search', component: SearchComponent },
      { path: 'contacts', component: ContactsMarketingComponent },
      { path: 'inscriptions', component: SigInListComponent },
     
      

    ], 
  },
  {
    path: 'directeur',
    component: EtbalissementemplComponent,
    canActivate: [AuthGuard],
    
    children: [
     
      { path: '', component: DirecteurHomeComponent },
      { path: 'activites', component: MatieresComponent },
      { path: 'activites/ajout-activite', component: AddMatiereComponent },
      { path: 'activites/modification-activite/:id', component: UpdateMatiereComponent },
      { path: 'puericulteurs', component: EnseignantsComponent },
      { path: 'puericulteurs/ajout-puericulteur', component: AddEnseignantComponent },
      { path: 'puericulteurs/modification-puericulteur/:id', component: UpdateEnseignantComponent },
      { path: 'enfants', component: EnfantsComponent },
      { path: 'enfants/ajout-enfant', component: AddEnfantComponent },
      { path: 'enfants/modification-enfant/:id', component: UpdateEnfantComponent },
      { path: 'parents', component: ParentsComponent },
      { path: 'parents/ajout-parent', component: AddParentComponent },
      { path: 'parents/modification-parent/:id', component: UpdateParentComponent },
    
      

    ],
  },
  {
    path: 'puericulteur',
    component: TemplEnseignantComponent,
    canActivate: [AuthGuard],
   

    children: [
      { path: '', component: EnseignantHomeComponent },
      { path: 'profil', component: ProfilEnseignantComponent },
      { path: 'profil/modification-informations/:id', component: UpdateInformationsComponent },
      { path: 'profil/modification-motDePasse/:id', component: UpdatePasswordComponent },
      // { path: 'profil/modification-photo/:id', component: UpdatephotoComponent },
      // { path: 'classes', component: ClassesEnseigComponent },
      { path: 'liste-enfants', component: ListEnfantsComponent },
      // { path: 'liste-enfants/Ajout-photo/:idEnf', component: AjoutPhotoEnfantComponent },
      // { path: 'liste-enfants/Liste-photos/:idEnf', component: ListesPhotosEnfantsComponent },
      // { path: 'liste-enfants/liste-comptes-rendues/:idEnf', component: ListStatemntsByChildComponent },
      { path: 'liste-enfants/details/:idEnf', component: DetailsEnfantComponent },
      { path: 'activites', component: MatieresEnseigComponent },
  ]
  },

  {
    path: 'parent',
    component: TemplParentComponent,
    canActivate: [AuthGuard],
    children: [
      //{ path: '', component: MainComponent },
      { path: '', component: AccueilComponent },
      { path: 'liste-enfants', component: ListesEnfantsComponent },
      { path: 'liste-enfants/liste-photos-enfants/:id', component: ListesPhotosEnfantsByParentComponent },
      { path: 'liste-enfants/liste-comptes-rendus/:id', component: ListStatementsComponent },
      { path: 'liste-enfants/details-enfant/:id', component: ParentdetailsEnfantComponent },
     

    ],
  },
  { path: '**', redirectTo: '/Connexion' } // Wildcard route for unknown paths
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    //imports: [RouterModule.forRoot(routes)],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

