import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { io } from 'socket.io-client';
import { User } from 'src/app/_models/user.model';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ClasseService } from 'src/app/_services/classe.service';
import { EtablissementService } from 'src/app/_services/etablissement.service';
import { ModuleService } from 'src/app/_services/module.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { UserService } from 'src/app/_services/user.service';
import { MatieresComponent } from '../../etbalissementempl/matieres/matieres.component';
import { SharedService } from 'src/app/_services/shared.service';
@Component({
  selector: 'app-templ-enseignant',
  templateUrl: './templ-enseignant.component.html',
  styleUrls: ['./templ-enseignant.component.scss']
})
export class TemplEnseignantComponent implements OnInit {
  
  captcha :string;
  email :string
  toutesModules;
  user;
  currentUser: User;
  message: "";
  matieres
  type
  test ="créche"
  notifications
  notifEtat ;
  private socket: any;
  public data: any;
  modules: any;
  resultsModules;
  isSidebarVisible: boolean = true;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private token :TokenStorageService
    ,private toastr: ToastrService
    ,private notificationService :NotificationService
    ,private userService :UserService
    ,private etablissementService :EtablissementService
    ,private sharedService: SharedService

     
  ) {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    
    this.sharedService.isSidebarVisible$.subscribe((isVisible) => {
      this.isSidebarVisible = isVisible;
    });
   
  }

  ngOnInit(): void {
    //this.getModules()
    this.captcha = '';
    this.email = 'khalilmeddeb2@gmail.com';
    this.currentUser = this.token.getUser();
    this.type=this.currentUser[0].etablissement.type
    this.modules=this.currentUser[0].etablissement.module
    this.getModules()
 
    
    
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/Connexion']);
  }
 
  changerEtat()
  {
   this.notifEtat = !this.notifEtat;
   this.getNotifications();
  }
  getNotifications(){
    
    
   this.notificationService.getNotificationsByEnseignant().subscribe(e=>{
    
       this.notifications=e
          })   
  }

  onChange(id)
  {
    this.notificationService.getChangeSelect(id).subscribe(e=>{
    
         })   

  }
  getModules(){
      this.etablissementService.getModules(this.currentUser[0].etablissement._id).subscribe(e=>{
        this.resultsModules=e;
        //his.etbalissement.role.id = e[0]._id

       
       
      
            },error=>{})
    }

    public resolved(captchaResponse :string)
    {
      this.captcha =captchaResponse
    }

    notif() {
      
      this.router.navigate(['/Enseignant/tests']);
    }
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    }
}
